<template>
  <DialogModal :show="message || errors" @close="message=null; errors=null">
    <template #title>
      <span v-if="message"     class="text-green-600">✅ OK</span>
      <span v-else-if="errors" class="text-red-800">❌ {{ $t('error') }}</span>
    </template>
    <template #content>
       {{ message }}
       <span v-for="error in errors" :key="error">
         {{ error }}<br>
       </span>
    </template>
    <template #footer>
      <SecondaryButton @click="message=null; errors=null">
        {{ $t('close') }}
      </SecondaryButton>
    </template>
  </DialogModal>
</template>

<script>
import DialogModal from '@/Components/DialogModal.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';

export default
{
  props: {
    message: {
      type: String,
      default: null
    },
    errors: {
      type: Array,
      default: null
    }
  },
  components:
  {
    DialogModal,
    SecondaryButton,
  }
}
</script>
